body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: currentColor;
  text-decoration: none;
}

.large-checkbox {
  width: 20px;
  height: 20px;
}

.swal2-container {
  z-index: 20000 !important;
}

/* Forçar tema claro */
:root {
  color-scheme: light;
}

@media (prefers-color-scheme: dark) {
  /* Desativar estilos para tema escuro, se houver */
}
